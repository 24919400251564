<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        to="/"
      >
        <!-- <vuexy-logo /> -->
        <custom-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ navbarTitle }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-overlay
          :show="show"
          rounded="sm"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ $t('Welcome to') }} {{ navbarTitle }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('Please select your application') }}
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="submit"
              >
                <!-- application -->
                <b-form-group
                  :label="$t('Application')"
                  label-for="login-application"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Application"
                    vid="application"
                    rules="required"
                  >
                    <v-select
                      v-model="application"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="applicationData"
                      :reduce="applicationData => applicationData._id"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="$t('Username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      name="login-username"
                      :placeholder="$t('Username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('Submit account deletion request') }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import axios from '@axios'

import {
  // eslint-disable-next-line no-unused-vars
  initialAbility,
  rootAbility,
  franchiseAbility,
  companyAbility,
  eCommerceAbility,
} from '@/libs/acl/config'
// eslint-disable-next-line no-unused-vars
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    CustomLogo,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // eslint-disable-next-line no-unneeded-ternary
      application: '',
      username: process.env.NODE_ENV === 'production' ? '' : 'gameprem',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      show: false,
      applicationData: [],
      // validation rules
      required,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.applicationGet()
  },
  methods: {
    applicationGet() {
      axios
        .get('/api/web/application-public/get')
        .then(response => {
          // commit('GET', response.data)
          this.applicationData = response.data.data
        })
        .catch(error => {})
    },
    submit() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          axios
            .post('/api/web/application-public/send-delete', {
              applicationId: this.application,
              username: this.username,
            })
            .then(response => {
              // commit('GET', response.data)
              this.showToast(
                'success',
                'top-right',
                this.$t('Request sent'),
                'CheckIcon',
              )

              this.$router.push({ name: 'auth-login' })
            })
            .catch(error => {})
        }
      })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
